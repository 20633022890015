import React, { useEffect, useRef, useState } from 'react'
import { ArrowUpOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { DatePicker } from '../dayjsToMoment'
import { useDispatch, useSelector } from 'react-redux';
import { addNewFrequency, deleteSchedules, duplicateSchedules, updateEndTime, updateStartTime } from '../../actions/newSchedulesActions';
import moment from 'moment';
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import {DuplicateIcon, NewDeleteIcon, PlusIcon } from '../../assests/icon';
import DateRangeSeparator from "../../imgs/DateRangeSeparator.svg";
import DateRangeSeparatorSE from "../../imgs/DateRangeSeparatorSE.svg";
import DateRangeSeparatorMCD from "../../imgs/DateRangeSeparatorMCD.svg";
import CalendarIcon from "../../imgs/CalendarIcon.svg"
import CalendarIconSE from "../../imgs/CalendarIconSE.svg"
import CalendarIconMCD from "../../imgs/CalendarIconMCD.svg"
import { useTranslation } from '../../context/translationContext'
import { EditView } from './editView'

const { RangePicker } = DatePicker;

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
}

export const SingleTimeFram = ({ config, addNewSchedule, idx }) => {
    
    const { getTranslation } = useTranslation();
    const [startDate, setStartDate] = useState(config.start_date !== null ? moment(config.start_date) : null)
    const [endDate, setEndDate] = useState(config.expiry_date !== null ? moment(config.expiry_date) : null)
    const [scheduleId, setScheduleId] = useState(config.scheduleId)

    const newScheduleStore = useSelector(state => state.newScheduleStore.schedules)
	const dashboard = useSelector(state => state.dashboard);
    const dispatch = useDispatch()
    const ref = useRef();

    useEffect(() => {
        if (config.start_date !== null)
            setStartDate(config.start_date)
        else
            setStartDate(null)
        if (config.expiry_date !== null)
            setEndDate(config.expiry_date)
        else
            setEndDate(null)
        if (config.scheduleId)
            setScheduleId(config.scheduleId)
        else
            setScheduleId(null)
    }, [config])

    const onChangeTime = (dateArray) => {
        if (dateArray[0])
            dispatch(updateStartTime({ key: scheduleId, startDate: moment(dateArray[0]).format("YYYY-MM-DD") }))
        if (dateArray[1]) {
            dispatch(updateEndTime({ key: scheduleId, endDate: moment(dateArray[1]).format("YYYY-MM-DD") }))
        }
    }

    const handleNewFreq = () => {
        dispatch(addNewFrequency({ key: scheduleId }))
    }

    const handleDeleteSchedule = () => {
        const config = {
            title: getTranslation('Are you Sure ?'),
            icon: null,
            content: getTranslation("This time frame will be delete forever, this process is not reversible."),
            okText: getTranslation("Delete"),
            cancelText: getTranslation("Cancel"),
            className: `cs-modal controls-action-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`,
            onOk: () => dispatch(deleteSchedules({ key: scheduleId }))
        };
        Modal.confirm(config)
    }

    const handleDuplicateSchedule = () => {
        dispatch(duplicateSchedules({ key: scheduleId, timeFrames: [{
            start_date: moment().format('YYYY-MM-DD'),
            expiry_date: moment().format('YYYY-MM-DD')
        }] }))
    }

    return (
        <div className="mr-10 mt-10 mb-20">
            <div className="d jc-sb ">
                <div className="d">
                    <div className="mr-10">
                        <RangePicker 
                        popupClassName={!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                            dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}
                        suffixIcon={<img src={!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? CalendarIconSE : dashboard?.customerTheme === 'mcdonalds' ? CalendarIconMCD : CalendarIcon} alt="calendar-icon" />}
                        separator={
                            <img
                                src={
                                !_isEmpty(dashboard && dashboard?.customerTheme) &&
                                dashboard?.customerTheme === "schneider"
                                    ? DateRangeSeparatorSE : 
                                    dashboard?.customerTheme === "mcdonalds"
                                    ? DateRangeSeparatorMCD
                                    : DateRangeSeparator
                                }
                                alt='ant-date-range-separator'
                            />
                        } format="YYYY-MM-DD" disabledDate={disabledDate}
                            value={(startDate !== null && endDate !== null) ? [moment(startDate), moment(endDate)] : null}
                            onChange={onChangeTime} allowClear={false} className="red" />
                    </div>
                    {
                        startDate !== null && endDate !== null &&
                        <div>
                            <Button  className="yellow-button" onClick={handleNewFreq} >
                                <PlusIcon className="plus-icon-yellow" />
                                Add Frequency
                            </Button>
                        </div>
                    }
                </div>
                <div className='d ai-center'>
                    {newScheduleStore[scheduleId]?.['frequency']?.some(freqItem => !!freqItem?.schedules?.length) && <DuplicateIcon  onClick={() => handleDuplicateSchedule()} />}
                    <NewDeleteIcon onClick={() => handleDeleteSchedule()} />
                </div>
            </div>
            <div className={`d back-grey  ${(startDate !== null && endDate !== null) ? 'jc-sb' : 'jc-c'}`}>
                {(startDate !== null && endDate !== null) ?
                    <>
                        <div className="d event-cards-container">
                            {/* All  Cards */}
                            <EditView scheduleId={scheduleId} />
                        </div>
                    </> :
                    <div className="br-1 d color-grey jc-c ai-center mt-20 arrow-container">
                        <ArrowUpOutlined className="mr-5" /> {getTranslation('Select Date Range to Add Control')}
                    </div>}
            </div>
            {!!addNewSchedule &&  
            <Button className="grey-button-outline mt-10" onClick={() => addNewSchedule()} >
                <PlusIcon className="plus-icon-black"/>
                {getTranslation("Add schedule")}
             </Button>}

        </div >
    )
}
