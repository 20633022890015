import { WarningOutlined } from '@ant-design/icons'
import { Button, Space, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomFreqSelect } from '../custom-freq-select/CustomFrqSelect'
import { SimpleEventCard } from './EventCard'
import _isEmpty from 'lodash/isEmpty'
import { addNewState, deleteFrequency, deleteState, updateEditStatus } from '../../actions/newSchedulesActions'
import { DeleteIcon, DeleteIconMCD, PlusIcon, PlusIconMCD } from '../../assests/icon'
import { useTranslation } from '../../context/translationContext'

export const FrequencyView = ({ freqData, freqId, scheduleId, deleteTrue }) => {
    const { getTranslation } = useTranslation();
    const [selectedStateId, setSelectedStateId] = useState(null)

    const editStatus = useSelector(state => state.newScheduleStore.currEdit)
    const equipmentReducer = useSelector(state => state.equipmentReducer)
	const dashboard = useSelector(state => state.dashboard)
    // const [scheduleLen, setScheduleLen] = useState(freqData.schedules.length || 0)
    const dispatch = useDispatch()

    // useEffect(() => {
    //     setScheduleLen(freqData.schedules.length)
    // }, [freqData, freqData.schedules])

    useEffect(() => {
        if (!_isEmpty(editStatus)) {
            const { freqId, stateId } = editStatus;
            if (editStatus.scheduleId === scheduleId || editStatus.scheduleId === null) {
                setSelectedStateId(stateId)
            }
        }
    }, [editStatus, editStatus.stateId, scheduleId])

    const handleDelete = (stateId) => {
        const config = {
            title: getTranslation('Are you Sure ?'),
            icon: null,
            content: getTranslation("This event will be delete forever, this process is not reversible."),
            okText: getTranslation("Delete"),
            cancelText: getTranslation("Cancel"),
            className: `cs-modal controls-action-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`,
            onOk: () => dispatch(deleteState({ key: scheduleId, freqId, stateId }))
        };
        Modal.confirm(config)
    }

    const handleEdit = (stateId) => {
        dispatch(updateEditStatus({ scheduleId, freqId, stateId }))
    }

    const handleNew = () => {
        dispatch(addNewState({ key: scheduleId, freqId, newState: { ["00:00"]: { ...equipmentReducer.defaultValue } } }))
        // dispatch(updateEditStatus({ scheduleId, freqId, stateId: scheduleLen }))
    }

    const handleDeleteFreq = () => {
        let config = {};
        config = {
            title: getTranslation('Are you Sure ?'),
            icon: null,
            content: getTranslation("This frequency will be delete forever, this process is not reversible."),
            okText: getTranslation("Delete"),
            cancelText: getTranslation("Cancel"),
            className: `cs-modal controls-action-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`,
            onOk: () => dispatch(deleteFrequency({ key: scheduleId, freqId }))
        };
        if (deleteTrue === 0) {
            config = {
                title: getTranslation('You cannot delete this!'),
                icon: null,
                content: getTranslation("There should be a at least one frequency in a schedule."),
                okText: getTranslation("Ok"),
                cancelText: getTranslation("Cancel"),
                className: `cs-modal controls-action-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                    dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`,
            };
        }
        Modal.confirm(config)
    }

    const addEventDisable = () => {
        if (freqData) {
            if (freqData.isDate) {
                if (!freqData.date)
                    return true
            } else {
                if (freqData.days_of_week.length === 0)
                    return true
            }
        }
        return false
    }

    return (
        <div className="back-grey frequency-container" >
            <div className="display-column">
                <div className="d jc-sb mb-10">
                    {/* Select frequency and add Event button */}
                    {freqData && <Space><CustomFreqSelect value={freqData} scheduleId={scheduleId} freqId={freqId} />
                        <Button className="red-light-button delete-btn-icon" onClick={handleDeleteFreq} icon={(!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds')  ? <DeleteIconMCD /> : <DeleteIcon />} />
                    </Space>}
                    <Button className={`green-button ${!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds' && ' change-btn-color-red'}`} onClick={handleNew} disabled={addEventDisable()} >{getTranslation('Add Event')} {(!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds') ? <PlusIconMCD /> : <PlusIcon />} </Button>

                </div>
                <div className="d" style={{ overflowX: 'auto' }}>
                    {/* All Cards */}
                    {freqData && freqData.schedules.map((schedulesState, scheduleStateId) =>
                        <SimpleEventCard key={scheduleStateId}
                            isActive={selectedStateId !== null && selectedStateId === scheduleStateId}
                            onEdit={() => handleEdit(scheduleStateId)}
                            deleteState={() => handleDelete(scheduleStateId)}
                            schedulesState={schedulesState} scheduleStateId={scheduleStateId} />)}
                </div>
            </div>
        </div>
    )
}
