import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { GetCustomTimePicker, RenderActuatorWidget, TimeSelect } from './allActuator'
import _isEmpty from 'lodash/isEmpty';
import { updateEditStatus, updateState } from '../../actions/newSchedulesActions';
import { sortAccToPriority } from '../../lib/softMapping';
import { useTranslation } from '../../context/translationContext';

export const Actuator = ({ scheduleId, scheduleData }) => {
    const { getTranslation } = useTranslation();
    const [state, setState] = useState(null);

    const [selectedFreq, setSelectedFreq] = useState(null)
    const [selectedStateID, setSelectedStateID] = useState(null)

    const equipmentReducer = useSelector(state => state.equipmentReducer)
    const editStatus = useSelector(state => state.newScheduleStore.currEdit)

    const dispatch = useDispatch();

    useEffect(() => {
        if (!_isEmpty(editStatus)) {
            const { freqId, stateId } = editStatus;
            if (editStatus.scheduleId === scheduleId) {
                setSelectedFreq(freqId);
                setSelectedStateID(stateId);
            }
        }
    }, [editStatus, scheduleId])

    useEffect(() => {
        if (!_isEmpty(scheduleData)) {
            if (scheduleData.frequency.length && selectedFreq !== null && selectedStateID !== null)
                setState(getCurrState(scheduleData.frequency))
        }
    }, [scheduleData, selectedStateID, selectedFreq])

    const getCurrState = (freqData) => {
        let state = {}
        for (var i in freqData) {
            if (i == selectedFreq) {
                state = freqData[i].schedules[selectedStateID]
                break;
            }
        }
        if (state && !_isEmpty(state)) {
            let [key, value] = Object.entries(state)[0];
            return { ...value, Time: key }

        } return null
    }

    const update = (invidualState) => {
        if (state && !_isEmpty(state)) {
            invidualState = { [state.Time]: { ...state, ...invidualState } }
        } else {
            invidualState = { ["00:00"]: { ...equipmentReducer.defaultValue } }
        }
        dispatch(updateState({ key: scheduleId, freqId: selectedFreq, stateId: selectedStateID, newState: invidualState }))
    }

    const updateTime = (time) => {
        let tempState = {}
        if (state && !_isEmpty(state)) {
            tempState = { [time]: { ...state } }
        } else {
            tempState = { [time]: {} }
        }
        dispatch(updateState({ key: scheduleId, freqId: selectedFreq, stateId: selectedStateID, newState: tempState }))
    }
    const callback = (key, value) => {
        setState({ ...state, })
    }

    const onSave = () => {
        dispatch(updateEditStatus({ scheduleId: null, freqId: null, stateId: null }))
    }

    return (
        <div>
            <div className="wrapper d jc-se ai-c mt-30 widgets-grid-container">
                <div>
                    <TimeSelect stateData={state} updateTime={updateTime} />
                </div>
                {/* <div className="horizontal-hr" /> */}
                {equipmentReducer.streamFunctions && sortAccToPriority(equipmentReducer.streamFunctions).map((config, idx) =>
                    <RenderActuatorWidget actuationConfig={config} idx={idx} key={idx} isEdit={true}
                        maxLen={equipmentReducer.streamFunctions.length}
                        updateState={(invidualState) => update(invidualState)}
                        stateData={state} />)}
            </div>
            <div className="custom-center mb-10">
                <Button className="red-button" onClick={() => onSave()} >{getTranslation('Save Controls')}</Button>
            </div>
        </div>
    )
}
