import React, { useRef } from 'react'
import { Button, Modal, Space } from 'antd'
import { CustomFreqSelect } from '../../components/custom-freq-select/CustomFrqSelect'
import { ComponentSheet } from '../../components/sheet'
import { downloadExcel } from '../../lib/download-excel'
import forEach from 'lodash/forEach'
import _isEmpty from 'lodash/isEmpty'
import { DeleteIcon, DeleteIconMCD, DownloadIcon } from '../../assests/icon'
import { deleteFrequency } from '../../actions/bulkActions'
import { useDispatch, useSelector } from 'react-redux'
import { downloadMultiSheets } from '../../lib/downloadMultiSheet'
import { useTranslation } from '../../context/translationContext'

export const FrequencyView = ({ freqData, freqId, timeFrameId, updateSelectedFre, startDate, endDate, allSchedules }) => {
    const { getTranslation } = useTranslation();
    const ref = useRef();

    const bulkReducer = useSelector(state => state.bulkReducer)
    const dispatch = useDispatch();
	const dashboard = useSelector(state => state.dashboard)

    const handleDownloadExcel = () => {
        let allSchedules = []
        for (let key of Object.keys(bulkReducer.schedules))
            allSchedules.push({ ...bulkReducer.schedules[key], timeFrameId: key })
        let allDataSource = [];
        let allHeaders = [];
        let allTitle = []
        for (let schedule of allSchedules) {
            let { dataSource, excelHeader } = getSheetDetails(schedule["frequency"])
            allTitle.push(schedule.timeFrameId);
            allDataSource.push(dataSource);
            allHeaders.push(excelHeader);
        }
        downloadMultiSheets(allDataSource, allTitle, allHeaders, "Schedules");
    }

    const getSheetDetails = (allFreq) => {
        let headers = ["site", "equipemnt"];
        let dataSource = [];

        forEach(allFreq[0]["schedules"][1], stream => headers.push(stream.value));

        // Default Column : Time
        let totalStreamCount = (Object.keys(bulkReducer['validationConstraint'])?.length ?? 0) + 1;

        let excelHeader = headers.map((colName, id) => {
          let unit = bulkReducer['validationConstraint']?.[colName]?.unit;
          
          // default columns skipped: >= 2, followed with offset: - 2, 
          let eventId = id >= 2 ? parseInt((id - 2) / totalStreamCount + 1) : '';

          return {
            header: colName + (!!unit ? ` ( ${unit} ) ` : ' ') + eventId,
            key: `${colName}_${id}`,
            width: !!unit ? 30 : 20,
          };
        });

        excelHeader = [{
            header: "Start Date",
            key: "startDate",
            width: 20
        }, {
            header: "End Date",
            key: "endDate",
            width: 20
        }, {
            header: "Frequency",
            key: "frequency",
            width: 20
        }, ...excelHeader]

        for (let freqData of allFreq) {
            let frequency
            let gridData = freqData.schedules;

            if (freqData.isDate) {
                frequency = freqData.date
            } else {
                if (freqData.days_of_week)
                    frequency = freqData.days_of_week.join(',')
            }

            if (gridData) {

                for (let row = 2; row < gridData.length; row++) {
                    let tempState = {}
                    for (let rowValue = 0; rowValue < gridData[row].length -1 ; rowValue++) {
                        Object.assign(tempState, { [`${headers[rowValue]}_${rowValue}`]: gridData[row][rowValue+1]['value'] })
                    }
                    Object.assign(tempState, { startDate, endDate, frequency })
                    dataSource.push(tempState)
                }
            }
        }
        return { dataSource, excelHeader }
    }

    const handleDeleteFreq = () => {
        let config = {};
        config = {
            title: getTranslation('Are you Sure ?'),
            icon: null,
            content: getTranslation("This frequency will be delete forever, this process is not reversible."),
            okText: getTranslation("Delete"),
            cancelText: getTranslation("Cancel"),
            className: `cs-modal controls-action-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`,
            onOk: () => { dispatch(deleteFrequency({ key: timeFrameId, freqId })); updateSelectedFre(freqId - 1) }
        };
        Modal.confirm(config)
    }

    return freqData ?
        <div>
            <div className="d jc-sb mt-10">
                <Space>
                    <CustomFreqSelect value={freqData} freqId={freqId} scheduleId={timeFrameId} isBulk={true} />
                    {freqId !== 0 && <Button className="red-light-button delete-btn-icon"
                        onClick={handleDeleteFreq} icon={(!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds')  ? <DeleteIconMCD /> : <DeleteIcon />} />}
                </Space>
                <Space>
                    <DownloadIcon onClick={handleDownloadExcel} className="ml-10 text-red cs-title download-icon-color" />
                    <Button className={`green-button ${!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds' && ' change-btn-color-red'}`} onClick={() => ref.current.addWidget()} > {getTranslation("Add Event")} </Button>
                </Space>
            </div>
            <div className="mt-20 cs-sheet" style={{ maxHeight: '60vh', overflow: 'auto', minHeight: '60vh' }}>
                {/* SpreadSheet */}
                <ComponentSheet ref={ref} gridData={freqData.schedules} freqId={freqId} timeFrameId={timeFrameId} />
            </div>
        </div>
        : null
}
