import React, { useEffect, useState } from 'react'
import { Divider } from 'antd'
import { FrequencyView } from './frequencyView'
import { Actuator } from './actuator';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty'
import { addNewFrequency } from '../../actions/newSchedulesActions';

export const EditView = ({ scheduleId }) => {

    const [scheduleData, setScheduleData] = useState(null)
    // TODO: make it come from currEdit
    const [selectedFreq, setSelectedFreq] = useState(null)

    const newScheduleStore = useSelector(state => state.newScheduleStore.schedules)
    const editStatus = useSelector(state => state.newScheduleStore.currEdit)
    const dispatch = useDispatch()


    useEffect(() => {
        if (!_isEmpty(newScheduleStore)) {
            if (newScheduleStore[scheduleId]) {
                setScheduleData(newScheduleStore[scheduleId])
                const {frequency } = newScheduleStore[scheduleId]
                if (frequency.length === 0)
                    handleNewFreq()
            }
        } else {
            setScheduleData(null)
        }
    }, [newScheduleStore, scheduleId])

    useEffect(() => {
        if (!_isEmpty(editStatus)) {
            const { freqId } = editStatus;
            if (editStatus.scheduleId === scheduleId || editStatus.scheduleId === null) {
                setSelectedFreq(freqId);
            }
        }
    }, [editStatus, scheduleId])

    const handleNewFreq = () => {
        dispatch(addNewFrequency({ key: scheduleId }))
    }

    const renderView = () => {
        return (
            <div className="display-column schedules-outer-container">
              <div className='schedules-inner-container'>
                {scheduleData &&
                  scheduleData.frequency.map((data, idx) => {
                    return (
                      <div key={idx}>
                        {!!idx  &&  <Divider />}
                        {selectedFreq === null ? (
                          <FrequencyView
                            deleteTrue={scheduleData.frequency.length - 1}
                            scheduleId={scheduleId}
                            freqId={idx}
                            key={idx}
                            freqData={data}
                          />
                        ) : (
                          selectedFreq === idx && (
                            <FrequencyView
                              isActive={true}
                              key={idx}
                              deleteTrue={scheduleData.frequency.length - 1}
                              scheduleId={scheduleId}
                              freqId={idx}
                              freqData={data}
                            />
                          )
                        )}
                      </div>
                    );
                  })}
              </div>
              {selectedFreq !== null && editStatus.scheduleId === scheduleId && <Actuator scheduleData={scheduleData} scheduleId={scheduleId} onEditCallback={() => handleEdit(null)} />}
          </div>
        );
    }

    return (
        <>
            {renderView()}
        </>
    )
}
