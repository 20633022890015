import React, { useEffect, useState } from 'react'
import { Table, Input, Button } from 'antd'
import { DatePicker } from '../../components/dayjsToMoment';
import { SearchOutlined, ToTopOutlined } from '@ant-design/icons'
import { antdTableHelper } from './helper';
import { downloadEquipmentSchedules, getClientEquipmentMapping } from '../../elements/apis/controlApis';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone'
import _isEmpty from 'lodash/isEmpty';
import { setUserPermissions } from '../../actions/appActions';
import { toastSuccess, toastWarn } from '../../toast/toast';
import DateRangeSeparator from "../../imgs/DateRangeSeparator.svg";
import DateRangeSeparatorSE from "../../imgs/DateRangeSeparatorSE.svg";
import DateRangeSeparatorMCD from "../../imgs/DateRangeSeparatorMCD.svg";
import ArrowUp from "../../imgs/ArrowUp.svg"
import CalendarIcon from "../../imgs/CalendarIcon.svg"
import CalendarIconSE from "../../imgs/CalendarIconSE.svg"
import CalendarIconMCD from "../../imgs/CalendarIconMCD.svg"
import SearchIcon from "../../imgs/SearchIcon.svg"
import { useTranslation } from '../../context/translationContext';
import { translateDatasource } from '../../lib/utils';
import { getCurrentUnitSystem } from '../../lib/function-helpers';

const { RangePicker } = DatePicker;

export const DownloadSchedules = () => {
    const { getTranslation } = useTranslation();
    const [column, setColumn] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableData, setTableData] = useState([])
    const [extraColumn, setExtraColumn] = useState([]);
    const [filterTable, setFilterTable] = useState(null)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [range, setRange] = useState(null);
    const [currentData, setCurrentData] = useState(null);

    const customer_name = useSelector(state => state.appStatus.customer)
    const appPermissions = useSelector(state => state.appStatus.appPermissions)
	const dashboard = useSelector(state => state.dashboard);
    const dispatch = useDispatch()

    useEffect(() => {
        init()
    }, [])

    const setRangeHelper = (value) => {
        setRange(value)
    }

    useEffect(() => {
        antdTableHelper(tableData, setColumn, extraColumn, currentData, getTranslation)
    }, [tableData, extraColumn, currentData])

    const rowSelection = {
        selectedRowKeys: selectedRows,
        onChange: (selectedRowKeys, selectedRows) => {
            let keyArray = selectedRowKeys.toString()
            if (keyArray) setSelectedRows(keyArray.split(','));
            else setSelectedRows([])
        },
        getCheckboxProps: (record) => ({
            disabled: !record.enabled,
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    const onSearch = e => {
        let value = e.target.value

        const serachColumn = ["siteName", "region", "equipmentType", "equipmentName"];
        const filterTable = tableData.filter(o =>
            Object.keys(o).some(k =>
                serachColumn.includes(k) && String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setFilterTable(filterTable)
    };

    const init = async () => {
        try {
            let accptedData = ["City", "Region", "Size"];
            let siteMetaDataMap = {}
            let extraCol = {}

            let res;
            if(appPermissions['clientEquipmentMapping'] && appPermissions['clientEquipmentMapping']['data'])
                res = appPermissions['clientEquipmentMapping']
            else{
                res = await getClientEquipmentMapping()
                dispatch(setUserPermissions(res))
            }
            if (res && res.data && res.data.clientSet.edges) {
                let allData = []
                res.data.clientSet.edges.map(data => {
                    if (data.node.siteMetadata)
                        siteMetaDataMap = data.node.siteMetadata
                    for (let metaData of Object.keys(siteMetaDataMap)) {
                        if (accptedData.includes(metaData))
                            Object.assign(extraCol, { [metaData]: true })
                    }
                    let siteName = data.node.displayName;
                    let timezone = data.node.timezone

                    data.node.equipments.edges.map(equipmentData => {
                        let config = {
                            key: equipmentData.node.name,
                            ...siteMetaDataMap,
                            siteName,
                            equipmentName: equipmentData.node.displayName,
                            equipmentType: equipmentData.node.type !== null ?
                                equipmentData.node.type.displayName : "",
                            equipmentTypeKey: equipmentData.node.type !== null ?
                                equipmentData.node.type.name : "",
                        }

                        equipmentData.node.schedules !== null ?
                            Object.assign(config, {
                                "control":
                                    equipmentData.node.scheduleExpiryDate ? 
                                        moment(equipmentData.node.scheduleExpiryDate).diff(moment().tz(timezone)) >= 0 ? "Present" : "Absent" : "Absent",
                                enabled: true

                            })
                            :
                            Object.assign(config, {
                                "control": "Absent", enabled: true
                            })
                        allData.push(config)
                    })
                })
                setExtraColumn(Object.keys(extraCol))
                setTableData(allData)
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    const DownloadScheduleButton = () => {

        const downloadSchedules = async () => {
            if (range === null) {
                toast.warn("Please select valid range.", {icon: toastWarn()});
                return;
            }
            let [startDate, endDate] = range;
            let start_date = moment(startDate).format('YYYY-MM-DD')
            let end_date = moment(endDate).format('YYYY-MM-DD')

            let equipments = {}

            for (let row of tableData) {
                let { equipmentTypeKey, key } = row;
                if (selectedRows.includes(key)) {
                    if (equipments[equipmentTypeKey]) {
                        Object.assign(equipments, {
                            [equipmentTypeKey]: [
                                ...equipments[equipmentTypeKey],
                                key
                            ]
                        })
                    } else {
                        Object.assign(equipments, {
                            [equipmentTypeKey]: [key]
                        })
                    }
                }
            }

            try {
                let resp = await downloadEquipmentSchedules({
                    start_date, end_date,
                    customer_name, equipments,
                    unit_type: getCurrentUnitSystem(),
                    isDemoUser: appPermissions.isDemoUser,
                })
                if (resp) {
                    // setRange(null)
                    setSelectedRows([])
                    toast.success("Successfully downloaded the schedules.", {icon: toastSuccess()})
                }
            } catch (err) {
                console.error(err)
            }
        }

        return <Button className="red-light-button ml-10"
            onClick={() => downloadSchedules()} > {getTranslation('Download Schedules')} </Button>
    };

    const renderView = () => {
        return (
            <div className="display-column" style={{ width: '100%', overflow: 'auto' }} >
                <div className="d mt-10">
                    <Input bordered={false} prefix={<img src={SearchIcon} alt="search" width={'20px'} />} onChange={onSearch}
                        className="curve" placeholder={`${getTranslation("Type here to search")}...`} />
                    {selectedRows.length !== 0 && <DownloadScheduleButton />}
                </div>
                <div className="mt-10">
                    <Table
                        onChange={(pagination, filter, _, filteredAction) => setCurrentData(filteredAction.currentDataSource)}
                        id="control-action-table"
                        columns={column}
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        dataSource={(filterTable === null ? translateDatasource(tableData, getTranslation) : translateDatasource(filterTable, getTranslation))} />
                </div>
            </div>
        )
    }

    return (
        <div className="display-column">
            <div>
                <RangePicker
                    value={range ? [moment(range[0]), moment(range[1])] : null}
                    allowClear={false}
                    className="red mb-10"
                    onChange={setRangeHelper}
                    popupClassName={!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                        dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}
                    suffixIcon={<img src={!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? CalendarIconSE : dashboard?.customerTheme === 'mcdonalds' ? CalendarIconMCD : CalendarIcon} alt="calendar-icon" />}
                    separator={
                        <img
                            src={
                            !_isEmpty(dashboard && dashboard?.customerTheme) &&
                            dashboard?.customerTheme === "schneider"
                                ? DateRangeSeparatorSE : 
                                dashboard?.customerTheme === "mcdonalds"
                                ? DateRangeSeparatorMCD
                                : DateRangeSeparator
                            }
                            alt='ant-date-range-separator'
                        />
                    }
                />
            </div>
            <div className="d wrapper jc-c" style={{ minHeight: '85vh' }}>
                {range ? renderView() :
                    <div className="custom-center ai-center" style={{ textAlign: 'center' }}>
                        <h3><img src={ArrowUp} alt="arrow-up-icon" /> {getTranslation('Select Date Range to Download Schedules')}</h3>
                    </div>}
            </div>
        </div>
    )
}